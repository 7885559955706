.circle {
  z-index: 0;
  position: relative;
    height: 150px;
    width: 150px;
    border: 2% double wheat;
    background-color: transparent;
    background-image: url("../../images/clockbg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 0 0 5px wheat, 0 0 0 7px white, 0 0 0 10px gray;
    border-radius: 50%;
    display: inline-block;
  }

  .second {
    z-index: 3;
    position: relative;
    transform: rotate(-90deg);
    transform-origin: 18% 50%;
    top: 50%;
    left: 42%;
    height: 1%;
    width:45%;
    background: goldenrod;
  }

  
  .minute {
    z-index: 2;
    position: relative;
    transform: rotate(-90deg);
    transform-origin: 18% 50%;
    top: 50%;
    left: 44%;
    height: 1.2%;
    width:35%;
    background: goldenrod;
  }

  .hour {
    z-index: 1;
    position: relative;
    transform: rotate(-90deg);
    transform-origin: 0% 50%;
    top: 48%;
    left: 50%;
    height: 1.5%;
    width:20%;
    background: goldenrod;
  }

  .dot {
    z-index: 100;
    position: relative;
    transform-origin: 0 0;
    top: 46%;
    left: 48%;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: black;
  }


